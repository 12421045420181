import React from 'react';
import { connect } from 'react-redux';
import { FormControl, Button } from 'react-bootstrap';
import { fromPairs, upperFirst } from 'lodash';
import {
  storeTemplateSearchText,
  toggleUploadTemplateModal,
  toggleEditFieldsModal,
  getTemplates,
} from '../../../redux/modules/eSignAdmin';
import { contractTypesAll } from '../../../constants/contractType';
import { STATES_ABBREVIATIONS } from '../../../constants/general';

const toState = ({ eSignAdmin }) => ({
  value: eSignAdmin.templateSearchText,
});

const TemplateSearchForm = ({ getTemplates, toggleUploadTemplateModal, toggleEditFieldsModal }) => (
  <form
    style={{ display: 'flex', marginBottom: 8, justifyContent: 'space-evenly', flexWrap: 'wrap' }}
    onSubmit={e => {
      e.preventDefault();
      const formData = new FormData(e.currentTarget);
      /* eslint-disable no-unused-vars */
      const filterEntries = Array.from(formData.entries()).filter(([_, value]) => !!value);
      /* eslint-enable no-unused-vars */
      const filter = fromPairs(filterEntries);
      getTemplates(filter);
    }}
    noValidate={true}
  >
    <div style={{ flexGrow: 2, display: 'flex', flexFlow: 'row', gap: 12 }}>
      <FormControl componentClass="select" name="templateType" style={{ width: 120 }}>
        <option key="Any" value="">
          All types
        </option>
        {contractTypesAll.map(contractType => (
          <option key={contractType} value={contractType}>
            {contractType}
          </option>
        ))}
      </FormControl>
      <FormControl componentClass="select" name="state" style={{ width: 120 }}>
        <option key="Any" value="">
          All states
        </option>
        {STATES_ABBREVIATIONS.map(state => (
          <option key={state} value={state}>
            {state}
          </option>
        ))}
      </FormControl>
      <FormControl componentClass="select" name="language" style={{ width: 140 }}>
        <option key="Any" value="">
          All languages
        </option>
        {['english', 'spanish'].map(language => (
          <option key={language} value={language}>
            {upperFirst(language)}
          </option>
        ))}
      </FormControl>
      <FormControl name="contractYear" placeholder="2023A" style={{ width: 120 }} />
      <Button type="reset">Reset</Button>
      <Button bsStyle="primary" type="submit">
        Search
      </Button>
    </div>
    {'  '}
    <div style={{ display: 'flex', flexWrap: 'wrap', flexGrow: 1, justifyContent: 'space-evenly' }}>
      <Button
        style={{ flexGrow: 1, marginRight: '8px' }}
        type="button"
        bsStyle="primary"
        onClick={() => toggleUploadTemplateModal(true)}
      >
        Upload New
      </Button>
      <Button
        style={{ flexGrow: 1 }}
        type="button"
        bsStyle="primary"
        onClick={() => toggleEditFieldsModal(true)}
      >
        Edit Fields
      </Button>
    </div>
  </form>
);

export default connect(toState, {
  getTemplates,
  storeTemplateSearchText,
  toggleUploadTemplateModal,
  toggleEditFieldsModal,
})(TemplateSearchForm);
