import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert, Button, Glyphicon } from 'react-bootstrap';
import { isEmpty, get, chain, sortBy, without } from 'lodash';
import { compose } from 'recompose';
import { QuotesTypeGroup } from './Summary';
import {
  storeVscQuotePlanSelection,
  storeVSCExtraCoverageSelection,
  clearVSCExtraCoverageSelection,
  storeGapQuotePlanSelection,
  storeTwpQuotePlanSelection,
  selectedGapQuote,
  selectedTwpQuote,
  selectedVscQuote,
  vehicleYear,
} from '../../../redux/modules/newQuote';
import {
  CONTRACT_TYPE_VSC,
  CONTRACT_TYPE_GAP,
  CONTRACT_TYPE_TWP,
} from '../../../constants/contractType';
import { getVerificationOverride } from '../../../redux/modules/verificationOverride';

/**
 * Assigns the groupedQuotes to global window var, to use in automated tests.
 */
const QuotesExpose = ({ groupedQuotes }) => {
  if (!groupedQuotes) return null;
  useEffect(() => {
    // @ts-ignore-next-line
    window.QuotesExpose_groupedQuotes = groupedQuotes;
    return () => {
      // @ts-ignore-next-line
      delete window.QuotesExpose_groupedQuotes;
    };
  }, [groupedQuotes]);

  return <div data-test-id="QuotesExpose" />;
};

const contractTypeOrder = [CONTRACT_TYPE_VSC, CONTRACT_TYPE_GAP, CONTRACT_TYPE_TWP];

export function QuoteSummary(props) {
  const {
    router,
    vehicleFormValues: { quoteType = [] } = {},
    setNewQuoteType,
    request = {},
    groupedQuotes,
    selectedVscQuote,
    extraCoverages,
    selectedGapQuote,
    selectedTwpQuote,
    storeVSCExtraCoverageSelection,
    loading,
    serviceError,
    dealerSettings,
  } = props;
  const handleStoreQuotePlanSelection = (...args) => {
    const { storeQuotePlanSelection, clearVSCExtraCoverageSelection } = props;
    storeQuotePlanSelection(...args);
    clearVSCExtraCoverageSelection();
  };

  const handleStoreGapCoverage = (...args) => {
    const { storeGapCoverage } = props;
    storeGapCoverage(...args);
    // NOTE - We do not clear extra coverage in the case of GAP
  };

  const handleStoreTwpCoverage = (...args) => {
    const { storeTwpCoverage } = props;
    storeTwpCoverage(...args);
    // NOTE - We do not clear extra coverage in the case of TWP
  };

  const handleStoreCoverage =
    contractType =>
    (...args) => {
      if (contractType === CONTRACT_TYPE_VSC) return handleStoreQuotePlanSelection(...args);
      if (contractType === CONTRACT_TYPE_GAP) return handleStoreGapCoverage(...args);
      if (contractType === CONTRACT_TYPE_TWP) return handleStoreTwpCoverage(...args);
    };

  const selectedVSCQuoteId = get(selectedVscQuote, 'quoteId');
  const selectedGapQuoteId = get(selectedGapQuote, 'quoteId');
  const selectedTWPQuoteId = get(selectedTwpQuote, 'quoteId');
  const getSelectedCoverageId = contractType => {
    if (contractType === CONTRACT_TYPE_VSC) return selectedVSCQuoteId;
    if (contractType === CONTRACT_TYPE_GAP) return selectedGapQuoteId;
    if (contractType === CONTRACT_TYPE_TWP) return selectedTWPQuoteId;

    return undefined;
  };

  const plansByContractTypePairs = useMemo(
    () =>
      chain(groupedQuotes)
        .toPairs()
        .map(([planName, plan]) => ({ ...plan, planName }))
        .groupBy('contractType')
        .mapValues(typeQuotes => sortBy(typeQuotes, 'index'))
        .toPairs()
        .sort(([contractType]) => contractTypeOrder.indexOf(contractType))
        .value(),
    [groupedQuotes],
  );

  const unapplicableTypes = useMemo(
    () =>
      quoteType.filter(
        requestedContractType =>
          !plansByContractTypePairs.find(
            ([planContractType]) => planContractType === requestedContractType,
          ),
      ),
    [quoteType, plansByContractTypePairs],
  );

  const unapplicableTypesAlert = !loading &&
    plansByContractTypePairs.length > 0 &&
    unapplicableTypes.length > 0 && (
      <Alert
        bsStyle="warning"
        style={{
          display: 'flex',
          flexFlow: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          margin: 20,
        }}
      >
        <h4>Vehicle is excluded from {unapplicableTypes.join(' and ')} coverage</h4>
        <Button onClick={() => setNewQuoteType(without(quoteType, ...unapplicableTypes))}>
          Continue without {unapplicableTypes.join(' and ')}
        </Button>
        <br />
        <Button bsStyle="link" onClick={() => router.goBack()}>
          <Glyphicon glyph="pencil" />
          &nbsp; Change vehicle information
        </Button>
      </Alert>
    );

  return (
    <form noValidate={true} data-test-id="QuoteSummary">
      {!serviceError && (
        <>
          <h6 style={{ marginTop: 32 }}>
            The rates below include any applicable surcharges for 4x4, Turbo or V10
          </h6>
          <legend>
            <span>Plans</span>{' '}
            <span className="title-help">
              (Click coverage boxes below to expand and display a list of available options for the
              vehicle)
            </span>
          </legend>
          {isEmpty(groupedQuotes) && !loading && (
            <Alert bsStyle="warning">There were no quotes returned.</Alert>
          )}
          <div style={{ display: 'flex', gap: 20 }}>
            {plansByContractTypePairs.map(([contractType, plans]) => (
              <QuotesTypeGroup
                key={contractType}
                dealerSettings={dealerSettings}
                contractType={contractType}
                plans={plans}
                selectedCoverageId={getSelectedCoverageId(contractType)}
                onCoverageClick={handleStoreCoverage(contractType)}
                extraCoverages={contractType === CONTRACT_TYPE_VSC ? extraCoverages : {}}
                changeExtraCoverages={
                  CONTRACT_TYPE_VSC ? storeVSCExtraCoverageSelection : undefined
                }
              />
            ))}
            {unapplicableTypesAlert}
          </div>

          <div style={{ margin: '32px 0' }}>
            <div>
              <b>Sale Information</b>
            </div>
            <div>
              <b>Dealership Name: </b>
              {request.dealerName}
            </div>
            <div>
              <b>VIN: </b>
              {request.vin}
            </div>
            <div>
              <b>Vehicle Y/M/M: </b>
              {request.vehicleYear} / {request.vehicleMileage} Miles / {request.vehicleDescription}
            </div>
            <div>
              <b>Vehicle Class: </b>
              {request.ratingVehicleClass}
            </div>
          </div>
        </>
      )}
      <QuotesExpose groupedQuotes={groupedQuotes} />
    </form>
  );
}

QuoteSummary.propTypes = {
  request: PropTypes.object,
  groupedQuotes: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  serviceError: PropTypes.string,
  selectedVscQuote: PropTypes.object,
  extraCoverages: PropTypes.object,
  selectedGapQuote: PropTypes.object,
  storeQuotePlanSelection: PropTypes.func.isRequired,
  storeVSCExtraCoverageSelection: PropTypes.func.isRequired,
  clearVSCExtraCoverageSelection: PropTypes.func.isRequired,
  storeGapCoverage: PropTypes.func.isRequired,
};

QuoteSummary.defaultProps = {
  request: {},
  serviceError: null,
  groupedQuotes: null,
  selectedVscQuote: null,
  extraCoverages: {},
  selectedGapQuote: null,
  selectedTwpQuote: null,
};

const mapFromConnect = state => {
  const newQuote = _.get(state, 'newQuote');
  const { vehicleFormValues } = newQuote;
  return {
    dealerSettings: _.get(state, 'dealerSettings.data'),
    dealer: _.get(state, 'dealer.details'),
    loading: _.get(state, 'page.loading'),
    newQuote,
    extraCoverages: newQuote.extraCoverages,
    vehicleFormValues,
    request: _.chain(_.get(newQuote, 'plans.ratingQuoteRequest'))
      .cloneDeep()
      .merge(_.pick(vehicleFormValues, ['commercialType', 'vehicleCondition']), {
        vehicleYear: vehicleYear({ newQuote }),
      })
      .pick([
        'quoteRequestId',
        'dealerName',
        'commercialType',
        'vehicleCondition',
        'vin',
        'vehicleDescription',
        'vehicleYear',
        'vehicleMileage',
        'ratingVehicleClass',
        'vehiclePurchasePrice',
        'vehicleSaleDate',
        'loanAmount',
      ])
      .value(),
    groupedQuotes: _.get(newQuote, 'plans.groupedRatingQuotes'),
    serviceError: newQuote.error,
    selectedVscQuote: selectedVscQuote({ newQuote }),
    selectedGapQuote: selectedGapQuote({ newQuote }),
    selectedTwpQuote: selectedTwpQuote({ newQuote }),
  };
};

export default compose(
  connect(mapFromConnect, {
    getVerificationOverride,
    storeQuotePlanSelection: storeVscQuotePlanSelection,
    storeVSCExtraCoverageSelection,
    clearVSCExtraCoverageSelection,
    storeGapCoverage: storeGapQuotePlanSelection,
    storeTwpCoverage: storeTwpQuotePlanSelection,
  }),
)(QuoteSummary);
