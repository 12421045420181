import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, Route, IndexRoute, browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import { load } from 'redux-load-api';
import _ from 'lodash';
import { stringToBoolean } from 'deep-cuts';
import store from './redux/store';
import { clearAllMessages, clearAllErrors } from './redux/modules';
import { clearForms } from './redux/modules/form';
import { infoDismiss } from './redux/modules/info';
import { notifClearAll } from './redux/modules/notifs';
import { clearDealerValidation } from './redux/modules/dealer';
import { clearState as clearNewQuoteState } from './redux/modules/newQuote';
import { AuthProvider, makeRouterRedirects } from './components/Auth';
import App from './containers/App';
import { ProfilePage, ProfileCompletePopup } from './containers/Profile';
import DealerSettings from './containers/DealerSettings';
import AllQuotes from './containers/AllQuotes';
import ContractIssueComplete from './containers/rate/ContractIssueComplete';
import Home from './containers/Home';
import PreHome from './containers/PreHome';
import NotFound from './containers/NotFound';
import OperationComplete from './containers/OperationComplete';
import UserAdmin from './containers/UserAdmin';
import MessagingAdmin from './containers/MessagingAdmin';
import EmailVerificationAdmin from './containers/EmailVerificationAdmin';
import NewQuote from './containers/new-quote/NewQuote';
import RateLegacy from './containers/rate/Rate';
import Pay from './containers/Pay';
import Statements from './containers/Statements';
import ESign from './containers/e-sign-admin/ESign';
import AdminContent from './components/AdminContent';
import { CacheAdmin } from './containers/CacheAdmin';
import ApiKeysManager from './containers/ApiKeysManager';
import { Tasks } from './containers/Tasks';
import { TaskIssuedContract } from './containers/Tasks/IssuedContract';
import { TaskUnknownType } from './containers/Tasks/UnknownType';
import VerficationOverride from './containers/VerificationOverride';
import QuoteEULA from './containers/new-quote/QuoteEULA';
import Quote from './containers/version-2/quote/Quote';
import Rate from './containers/version-2/rate/Rate';
import UploadFile from './containers/UploadFile';

import 'react-datetime/css/react-datetime.css'; // eslint-disable-line import/first
import './index.css';

// @deprecated TODO delete when Auth0 migration is over.
// TODO if it's not 2021, it's time to delete it.
import { MigrateFrom219ApiToAuth0 } from './containers/MigrateFrom219ApiToAuth0';

// Enchanced history
const history = syncHistoryWithStore(browserHistory, store);

const { userAuth, dealerAdminAuth, prcoAdminAuth } = makeRouterRedirects();

const onRouteChange = () => {
  store.dispatch(infoDismiss());
  store.dispatch(notifClearAll());
  store.dispatch(clearAllMessages());
  store.dispatch(clearAllErrors());

  if (
    !window.location.pathname.startsWith('/newQuote') &&
    !window.location.pathname.startsWith('/rate')
  ) {
    store.dispatch(clearForms());
  }

  if (window.location.pathname === '/') {
    store.dispatch(clearDealerValidation());
    store.dispatch(clearNewQuoteState());
  }

  window.scrollTo(0, 0);
  if (_.isFunction(_.get(window, 'parent.postMessage'))) {
    window.parent.postMessage('SCROLL_TOP', '*');
  }
};

load([App]);

// TODO: Use dash case for routes
ReactDOM.render(
  <Provider store={store}>
    <AuthProvider>
      <ProfileCompletePopup />
      <Router history={history}>
        <Route path="/" component={App} onChange={onRouteChange}>
          {/* Unauthenticated Routes */}
          {stringToBoolean(process.env.REACT_APP_FEATURE_FLAG_AUTH0_MIGRATION_PAGE) ? (
            <IndexRoute component={PreHome} />
          ) : (
            <IndexRoute component={Home} />
          )}
          <Route path="operationComplete" component={OperationComplete} />

          {
            // @deprecated TODO delete when Auth0 migration is over.
            // TODO if it's not 2021, it's time to delete it.
          }
          <Route path="MigrateFrom219ApiToAuth0" component={MigrateFrom219ApiToAuth0} />

          {/* Authenticated Routes */}
          <Route path="profile" component={userAuth(ProfilePage)} />
          <Route path="dealerSettings" component={userAuth(DealerSettings)} />
          <Route path="quote/eula" component={userAuth(QuoteEULA)} />
          <Route path="quote/rate" component={userAuth(Rate)} />
          <Route path="quote/*" component={userAuth(Quote)} />
          <Route path="newQuote" component={userAuth(NewQuote)} />
          <Route path="rate" component={userAuth(RateLegacy)} />
          <Route path="contractIssueComplete/:id" component={userAuth(ContractIssueComplete)} />
          <Route path="allQuotes" component={userAuth(AllQuotes)} />
          <Route path="pay" component={userAuth(Pay)} />
          <Route path="statements" component={userAuth(Statements)} />
          <Route path="v2/quote*" component={userAuth(Quote)} />

          {/* Task Routes */}
          <Route path="tasks" exact={true} component={userAuth(Tasks)} />
          <Route path="tasks/IssueContract/:taskId" component={userAuth(TaskIssuedContract)} />
          <Route path="tasks/:taskType/:taskId" component={userAuth(TaskUnknownType)} />

          {/* Admin Routes */}
          <Route path="admin" component={dealerAdminAuth(AdminContent)} />
          <Route path="admin/users" component={dealerAdminAuth(UserAdmin)} />
          <Route
            path="admin/override-verification"
            component={prcoAdminAuth(VerficationOverride)}
          />
          <Route path="admin/e-sign-administration" component={prcoAdminAuth(ESign)} />
          <Route path="admin/messaging-administration" component={prcoAdminAuth(MessagingAdmin)} />
          <Route
            path="admin/email-verification-administration"
            component={prcoAdminAuth(EmailVerificationAdmin)}
          />
          <Route path="admin/upload-file" component={prcoAdminAuth(UploadFile)} />
          <Route path="admin/cache" component={prcoAdminAuth(CacheAdmin)} />
          <Route path="admin/api-keys" component={prcoAdminAuth(ApiKeysManager)} />

          {/* Catch all route */}
          <Route path="*" component={NotFound} status={404} />
        </Route>
      </Router>
    </AuthProvider>
  </Provider>,
  document.getElementById('root'),
);
