import { sanitizeStrFromCenter } from './common';

export const normalizeUSPhone = raw => {
  if (!raw) return raw;

  const cleanA = raw.replace(/[^\d+]/g, '');
  const cleanWithoutPrefix = cleanA.startsWith('+1') ? cleanA.substring(2) : cleanA;
  return cleanWithoutPrefix.replace(/\+/g, '');
};

export const collapseUSPhone = (raw, sanitizeOptions) => {
  if (!raw) return raw;

  if (!sanitizeOptions) return normalizeUSPhone(raw);

  const sanitize = sanitizeStrFromCenter(strPart => strPart.replace(/[^()\-+ \d]/g, ''));

  return sanitize(raw, sanitizeOptions);
};
