import React, { useMemo } from 'react';
import { Button, Col, Grid, Jumbotron, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { markTaskAsRead } from '../../redux/modules/task';
import { TaskProgressPanel } from './ProgressPanel';
import { useOnTaskComplete } from './useOnTaskComplete';
import { useTaskEvents } from './useTaskEvents';

export const stepsDescs = [
  {
    title: 'Validate request',
    name: 'validateIssueContractData',
    weight: 5,
  },
  {
    title: 'Register request',
    name: 'registerInProgress',
    weight: 5,
  },
  {
    title: 'Save Lender',
    name: 'upsertLenderIfRequired',
    weight: 10,
  },
  {
    title: 'Send to PRco',
    name: 'requestFourD',
    weight: 20,
  },
  {
    title: 'Create documents',
    name: 'createDocuments',
    weight: 30,
  },
  {
    title: 'Register contract',
    name: 'registerIssuedContract',
    weight: 5,
  },
  {
    title: 'E-Sign Communication',
    name: 'createESignPackageIfRequired',
    weight: 20,
  },
  {
    title: 'Finalize',
    name: 'finalize',
    weight: 5,
  },
];

export const TaskIssuedContractDisplay = props => {
  const {
    params: { taskId },
    tasksMetas: { [taskId]: taskMeta } = {},
    router,
    markTaskAsRead,
  } = props;

  const { taskEvents, taskEventResult, errorIshEvents } = useTaskEvents(taskId);

  const { issuedContractId } = taskEventResult || {};

  const hasErrors = errorIshEvents.length > 0;

  const onComplete = useMemo(
    () =>
      issuedContractId && router && !hasErrors
        ? () => {
            markTaskAsRead(taskId);
            router.push(`/contractIssueComplete/${issuedContractId}`);
          }
        : undefined,
    [issuedContractId, router, hasErrors],
  );

  useOnTaskComplete(taskMeta, onComplete);

  const resultRow = issuedContractId && (
    <Row>
      <Col xs={10} xsOffset={1}>
        <Jumbotron style={{ textAlign: 'center' }}>
          <h2>Contract is ready</h2>
          <LinkContainer to={`/contractIssueComplete/${issuedContractId}`}>
            <Button bsStyle="primary">Go to contract</Button>
          </LinkContainer>
        </Jumbotron>
      </Col>
    </Row>
  );

  return (
    <Grid>
      <Helmet title="Tasks" />
      <Row>
        <Col xs={12}>
          <TaskProgressPanel
            taskMeta={taskMeta}
            stepsDescs={stepsDescs}
            taskEvents={taskEvents}
            defaultExpanded={hasErrors ? true : undefined}
            markTaskAsRead={markTaskAsRead}
          />
        </Col>
      </Row>
      {resultRow}
    </Grid>
  );
};

export const TaskIssuedContract = connect(
  ({ task: { tasksMetas } }) => ({
    tasksMetas,
  }),
  {
    markTaskAsRead,
  },
)(TaskIssuedContractDisplay);
