import _ from 'lodash';
import moment from 'moment';
import url from 'url';
import qs from 'qs';
import { isEmpty } from 'deep-cuts';
import api from '../../utils/service';
import { loadingCycle } from './page';
import { openDownload } from '../../utils/download';

const GET_ALL_ISSUED_CONTRACTS_SUCCESS = 'redux/issuedContract/GET_ALL_ISSUED_CONTRACTS_SUCCESS';
const GET_ALL_ISSUED_CONTRACTS_FAIL = 'redux/issuedContract/GET_ALL_ISSUED_CONTRACTS_FAIL';
const GET_ISSUED_CONTRACT_SUCCESS = 'redux/issuedContract/GET_ISSUED_CONTRACT_SUCCESS';
const GET_ISSUED_CONTRACT_FAIL = 'redux/issuedContract/GET_ISSUED_CONTRACT_FAIL';
const CREATE_ISSUED_CONTRACT_SUCCESS = 'redux/issuedContract/CREATE_ISSUED_CONTRACT_SUCCESS';
const CREATE_ISSUED_CONTRACT_FAIL = 'redux/issuedContract/CREATE_ISSUED_CONTRACT_FAIL';
const DELETE_ISSUED_CONTRACT_SUCCESS = 'redux/issuedContract/DELETE_ISSUED_CONTRACT_SUCCESS';
const DELETE_ISSUED_CONTRACT_FAIL = 'redux/issuedContract/DELETE_ISSUED_CONTRACT_FAIL';
const UPDATE_ISSUED_CONTRACT_SUCCESS = 'redux/issuedContract/UPDATE_ISSUED_CONTRACT_SUCCESS';
const UPDATE_ISSUED_CONTRACT_FAIL = 'redux/issuedContract/UPDATE_ISSUED_CONTRACT_FAIL';
const DOWNLOAD_ISSUED_CONTRACT_SUCCESS = 'redux/issuedContract/DOWNLOAD_ISSUED_CONTRACT_SUCCESS';
const DOWNLOAD_ISSUED_CONTRACT_FAIL = 'redux/issuedContract/DOWNLOAD_ISSUED_CONTRACT_FAIL';

const initialState = {};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_ALL_ISSUED_CONTRACTS_SUCCESS:
      return {
        ...state,
        list: action.data,
        error: null,
      };
    case GET_ISSUED_CONTRACT_SUCCESS:
    case UPDATE_ISSUED_CONTRACT_SUCCESS:
    case CREATE_ISSUED_CONTRACT_SUCCESS:
      return {
        ...state,
        result: action.data,
        error: null,
      };
    case GET_ALL_ISSUED_CONTRACTS_FAIL:
    case GET_ISSUED_CONTRACT_FAIL:
    case UPDATE_ISSUED_CONTRACT_FAIL:
    case CREATE_ISSUED_CONTRACT_FAIL:
    case DOWNLOAD_ISSUED_CONTRACT_FAIL:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}

export function getAllIssuedContracts({ dealerId, vin } = {}) {
  return dispatch => {
    const query = url.format({
      query: _.omitBy(
        {
          dealerId,
          vin,
        },
        isEmpty,
      ),
    });
    return loadingCycle(api.get(`/issued-contracts${query}`))
      .then(({ data }) => dispatch(getAllIssuedContractsSuccess(data)))
      .catch(error => dispatch(getAllIssuedContractsFail(error)));
  };
}

export function getAllIssuedContractsSuccess(data) {
  return {
    type: GET_ALL_ISSUED_CONTRACTS_SUCCESS,
    data,
  };
}

export function getAllIssuedContractsFail(error) {
  return {
    type: GET_ALL_ISSUED_CONTRACTS_FAIL,
    error: _.get(error, 'response.data.message', _.get(error, 'message')),
  };
}
export function getIssuedContractTasksIds(id) {
  return dispatch =>
    api
      .get(`/issued-contracts/${id}/tasks`)
      .then(({ data: { taskIds } }) => taskIds)
      .catch(error => dispatch(getIssuedContractFail(error)));
}

export function getIssuedContract(id) {
  return dispatch =>
    api
      .get(`/issued-contracts/${id}`)
      .then(({ data }) => {
        dispatch(getIssuedContractSuccess(data));
        return data;
      })
      .catch(error => dispatch(getIssuedContractFail(error)));
}

export function getIssuedContractSuccess(data) {
  return {
    type: GET_ISSUED_CONTRACT_SUCCESS,
    data,
  };
}

export function getIssuedContractFail(error) {
  return {
    type: GET_ISSUED_CONTRACT_FAIL,
    error: _.get(error, 'response.data.message', _.get(error, 'message')),
  };
}

export function createIssuedContract(data) {
  return dispatch =>
    loadingCycle(api.post('/issued-contracts', data))
      .then(({ data }) => {
        dispatch(createIssuedContractSuccess(data));
        return data;
      })
      .catch(error => dispatch(createIssuedContractFail(error)));
}

export function createIssuedContractSuccess(data) {
  return {
    type: CREATE_ISSUED_CONTRACT_SUCCESS,
    data,
  };
}

export function createIssuedContractFail(error) {
  return {
    type: CREATE_ISSUED_CONTRACT_FAIL,
    error: _.get(error, 'response.data.message', _.get(error, 'message')),
  };
}

export function deleteIssuedContract(id, query = {}) {
  return dispatch =>
    loadingCycle(api.delete(`/issued-contracts/${id}?${qs.stringify(query)}`))
      .then(({ data }) => dispatch(deleteIssuedContractSuccess(data)))
      .then(() => dispatch(getAllIssuedContracts()))
      .catch(error => dispatch(deleteIssuedContractFail(error)));
}

export function deleteIssuedContractSuccess(data) {
  return {
    type: DELETE_ISSUED_CONTRACT_SUCCESS,
    data,
  };
}

export function deleteIssuedContractFail(error) {
  return {
    type: DELETE_ISSUED_CONTRACT_FAIL,
    error: _.get(error, 'response.data.message', _.get(error, 'message')),
  };
}

export function updateIssuedContract(id, data = {}) {
  return dispatch =>
    loadingCycle(api.put(`/issued-contracts/${id}`, data))
      .then(({ data }) => dispatch(updateIssuedContractSuccess(data)))
      .then(() => dispatch(getAllIssuedContracts()))
      .catch(error => dispatch(updateIssuedContractFail(error)));
}

export function updateIssuedContractSuccess(data) {
  return {
    type: UPDATE_ISSUED_CONTRACT_SUCCESS,
    data,
  };
}

export function updateIssuedContractFail(error) {
  return {
    type: UPDATE_ISSUED_CONTRACT_FAIL,
    error: _.get(error, 'response.data.message', _.get(error, 'message')),
  };
}

export function downloadIssuedContract(id, type, filename, { testId = undefined } = {}) {
  return dispatch =>
    loadingCycle(
      api({
        url: `/actions/issued-contracts/${id}/download/${type}`,
        method: 'GET',
        responseType: 'blob',
      }),
    )
      .then(({ data }) => {
        openDownload(data, filename || `${type}-contract-${moment().format('YYYYMMDD')}`, {
          testId,
        });
        dispatch(downloadIssuedContractSuccess(data));
      })
      .catch(error => dispatch(downloadIssuedContractFail(error)));
}

export function downloadIssuedContractSuccess(data) {
  return {
    type: DOWNLOAD_ISSUED_CONTRACT_SUCCESS,
    data,
  };
}

export function downloadIssuedContractFail(error) {
  return {
    type: DOWNLOAD_ISSUED_CONTRACT_FAIL,
    error: _.get(error, 'response.data.message', _.get(error, 'message')),
  };
}
