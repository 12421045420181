import _ from 'lodash';
import memoize from 'lru-memoize';
import moment from 'moment';
import numeral from 'numeral';
import { maxStateLoanAmount } from './newQuoteValidation';
import {
  createValidator,
  required,
  money,
  integer,
  integerPhoneNumber,
} from '../../utils/validation';

// NOTE - I may add this to the deep-cuts npm module. Insane that _.isEmpty ignores numbers, maybe there is a reason.
export const isEmptyNumberOrString = val => _.isNil(val) || _.isNaN(val) || _.isEmpty(String(val));

export function minSaleDate(value) {
  const minDays = 20;
  const diff = moment().diff(moment.isMoment(value) ? value : moment(value, 'MM/DD/YYYY'), 'days');
  if (diff > minDays) {
    return `The sales date must be within the last ${minDays} days.`;
  }
}

function maxSaleDate(value) {
  if ((moment.isMoment(value) ? value : moment(value, 'MM/DD/YYYY')).isAfter(moment())) {
    return 'The sales date cannot be in the future.';
  }
}

export function maxRetailPriceOfVsc(value, { vehiclePrice }) {
  if (value >= 0.8 * parseFloat(vehiclePrice)) {
    return `Retail price of Vehicle Service Contract must be less 
      than 80% of the purchase price of the vehicle ${numeral(
        parseFloat(vehiclePrice) * 0.8,
      ).format('$0,0.00')}`;
  }
}

export function maxRetailGap({ dealerState, vehicleSaleDate }) {
  return (value, data) => {
    console.log(value, data);
    const {
      gapRate: { loanAmountOfGap },
    } = data;
    if (dealerState === 'OR' && 20 * parseFloat(value) > parseFloat(loanAmountOfGap)) {
      return 'State law in Oregon requires a retail price less than or equal to 5% of the loan amount.';
    } else if (
      dealerState === 'CA' &&
      25 * parseFloat(value) > parseFloat(loanAmountOfGap) &&
      vehicleSaleDate.isSameOrAfter(moment('01/01/2023', 'MM/DD/YYYY'))
    ) {
      return 'State law in California requires a retail price less than or equal to 4% of the loan amount.';
    } else if (parseFloat(value) >= parseFloat(loanAmountOfGap)) {
      return 'The retail price must be less than the loan amount.';
    }
  };
}

export const makeMaxLoanAmount =
  ({ maxStateLoanAmount }) =>
  props =>
  (value, data) => {
    const { maximumLoanAllowed, dealerState, contractYear } = props;

    const {
      gapRate: { kelleyBlueBook, bhph },
    } = data;

    if (!isEmptyNumberOrString(value)) {
      const parsedValue = parseFloat(value);
      const maxLoan = !_.isNil(maximumLoanAllowed)
        ? parseFloat(maximumLoanAllowed)
        : maxStateLoanAmount({ dealerState, contractYear });
      if (parsedValue > maxLoan) {
        return `Loan Amount of the GAP must be less than ${numeral(maxLoan).format('$0,0.00')}.`;
      } else if (bhph === 'Yes') {
        const rangeAboveBlueBookValue = 1.2 * (kelleyBlueBook || 0);
        if (parsedValue > rangeAboveBlueBookValue) {
          return `Loan Amount cannot be more than 120% of Kelley Blue Book Value: ${numeral(
            rangeAboveBlueBookValue,
          ).format('$0,0.00')}.`;
        }
      }
    }
  };

export const minLoanAmount = props => (value, data) => {
  const { dealerState, gapContractYear, vehicleSaleDate } = props;
  const {
    gapRate: { kelleyBlueBook },
  } = data;
  if (!isEmptyNumberOrString(value)) {
    const parsedValue = parseFloat(value);
    if (
      dealerState === 'CA' &&
      gapContractYear === '2023A' &&
      parsedValue <= parseFloat(kelleyBlueBook) * 0.7 &&
      vehicleSaleDate.isSameOrAfter(moment('01/01/2023', 'MM/DD/YYYY'))
    ) {
      return 'Loan Amount of the GAP must be more than 70% of the Kelley Blue Book Value.';
    }
  }
};

export const maxLoanAmount = makeMaxLoanAmount({
  maxStateLoanAmount,
});

const vscRateFormValidation = {
  'vscRate.issueType': required,
  'vscRate.saleDate': [required, minSaleDate, maxSaleDate],
  'vscRate.language': required,
  'vscRate.vehiclePrice': [required, money],
};

const gapRateFormValidation = {
  'gapRate.loanAmountOfGap': [],
  'gapRate.gapLoanTermInMonths': [required, integer],
  'gapRate.bhph': required,
};

const twpRateFormValidation = {
  'twpRate.retailPriceOfTwp': [required, money],
};

const customerInformationRateFormValidation = {
  'customer.firstName': required,
  'customer.lastName': required,
  'customer.address.street': required,
  'customer.address.city': required,
  'customer.address.state': required,
  'customer.address.zipCode': required,
};

const lenderInformationRateFormValidation = {
  'lender.companyName': required,
  'lender.address.street': required,
  'lender.address.city': required,
  'lender.address.state': required,
  'lender.address.zipCode': required,
  'lender.phoneNumber': integerPhoneNumber,
};

export const vscRateValidator = createValidator(vscRateFormValidation);
export const gapRateValidator = createValidator(gapRateFormValidation);
export const twpRateValidator = createValidator(twpRateFormValidation);
export const customerInformationRateValidator = memoize(10)(
  createValidator(customerInformationRateFormValidation),
);
export const lenderInformationRateValidator = memoize(10)(
  createValidator(lenderInformationRateFormValidation),
);

export const isLoanAmountDisabled = ({ dealerState, gapContractYear }) =>
  dealerState === 'CA' && gapContractYear === '2023A';

export const isKelleyBlueBookDisabled = ({ dealerState, gapContractYear, vehicleSaleDate }) =>
  dealerState === 'CA' &&
  gapContractYear === '2023A' &&
  moment(vehicleSaleDate).isSameOrAfter(moment('01/01/2023', 'MM/DD/YYYY'));
