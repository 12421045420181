import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import {
  required,
  email as emailValidation,
  match,
  notMatch,
  integerPhoneNumber,
  isZipCode,
  isOnlyAlphabetWithSymbols,
  exactLength,
  minLength,
} from '../../../utils/validation';
import ValidationInput from '../../../components/common/ValidationInput';
import ValidationSelect from '../../../components/common/ValidationSelect';
import ValidationCheckbox from '../../../components/common/ValidationCheckbox';
import { statesForSelection } from '../../../constants/general';
import { CUSTOMER_INFORMATION_RATE_FORM } from '../../../redux/modules/form';
import { verifyEmail } from '../../../redux/modules/email';
import { customerInformationRateValidator } from '../../../components/validation/rateValidation';
import Buttons from '../../../components/wizard/Buttons';
import { customerInformationRateTestDataIfRequested } from '../../../utils/testData';
import { receiveValuesFromQueryData } from '../../../utils/decorators';
import { QuoteTypePropType } from '../../../constants/newQuote';
import { getZipCodeAddress } from '../../../redux/modules/zipCode';

export const makeCustomerInformationRateFormAsyncValidation = ({ verifyEmail }) =>
  async function customerInformationRateFormAsyncValidation({ customer }) {
    const errors = {};
    const customerEmail = _.get(customer, 'email');
    // Note - make sure the customerEmail matches the basic regex of an email address before reaching out to server
    // Note - There are also cases where we do not have a customer email and only have a phone number
    if (customerEmail) {
      if (emailValidation(customerEmail) === undefined) {
        try {
          await verifyEmail(customerEmail);
        } catch (error) {
          _.set(errors, 'customer.email', _.get(error, 'message'));
        }
      }
    }
    const hasCoBuyer = _.get(customer, 'hasCoBuyer');
    const coBuyerEmail = _.get(customer, 'coBuyerEmail');
    // Note - make sure the coBuyerEmail matches the basic regex of an email address before reaching out to server
    if (hasCoBuyer && emailValidation(coBuyerEmail) === undefined) {
      try {
        await verifyEmail(coBuyerEmail);
      } catch (error) {
        _.set(errors, 'customer.coBuyerEmail', _.get(error, 'message'));
      }
    }
    return errors;
  };

const customerInformationRateFormAsyncValidation = makeCustomerInformationRateFormAsyncValidation({
  verifyEmail,
});

const customerInformationRateFormValueSelector = formValueSelector(CUSTOMER_INFORMATION_RATE_FORM);

const STATES_VALUES = statesForSelection();

export const phoneNumberRequired = ({ phoneNumber }) => phoneNumber !== undefined;
export const emailRequired = ({ eSign, phoneNumber }) => eSign || !phoneNumber;

export function CustomerInformationForm(props) {
  const { handleSubmit, dispatch } = props;
  const handleZipCodeChange = async e => {
    if (isZipCode(e.target.value) === undefined) {
      try {
        const ResponseData = await getZipCodeAddress(e.target.value);
        if (ResponseData.data.length) {
          dispatch(
            change(
              CUSTOMER_INFORMATION_RATE_FORM,
              'customer.address.city',
              _.get(ResponseData.data[0], 'city', ''),
            ),
          );
          dispatch(
            change(
              CUSTOMER_INFORMATION_RATE_FORM,
              'customer.address.state',
              _.get(ResponseData.data[0], 'state', ''),
            ),
          );
        }
      } catch (error) {
        return error;
      }
    }
  };

  return (
    <form
      className="form-horizontal"
      onSubmit={handleSubmit}
      noValidate={false}
      data-test-id="CustomerInformationForm"
    >
      <Field
        name="customer.firstName"
        type="text"
        component={ValidationInput}
        label="First Name"
        placeholder="eg. George"
        data-test-id="CustomerInformationForm-firstName"
      />
      <Field
        name="customer.middleInitial"
        type="text"
        component={ValidationInput}
        label="Middle Initial"
        placeholder="eg. R"
      />
      <Field
        name="customer.lastName"
        type="text"
        component={ValidationInput}
        label="Last Name"
        placeholder="eg. Jones"
        data-test-id="CustomerInformationForm-lastName"
      />
      <Field
        name="customer.address.zipCode"
        type="text"
        component={ValidationInput}
        label="Zip Code"
        placeholder="eg. 11101, 11101-1234"
        validate={[isZipCode]}
        onChange={handleZipCodeChange}
        data-test-id="CustomerInformationForm-zipCode"
      />
      <Field
        name="customer.address.street"
        type="text"
        component={ValidationInput}
        label="Address"
        placeholder="eg. 919 Main St."
        data-test-id="CustomerInformationForm-street"
      />
      <Field
        name="customer.address.city"
        type="text"
        component={ValidationInput}
        label="City"
        placeholder="eg. Lafayette"
        validate={[isOnlyAlphabetWithSymbols]}
        data-test-id="CustomerInformationForm-city"
      />
      <Field
        name="customer.address.state"
        type="text"
        component={ValidationSelect}
        label="State"
        options={STATES_VALUES}
        data-test-id="CustomerInformationForm-state"
      />
      <Field
        name="customer.phoneNumber"
        type="text"
        component={ValidationInput}
        label="Phone Number"
        placeholder="eg. 8882627890"
        validate={
          phoneNumberRequired(props)
            ? [integerPhoneNumber, exactLength(10), minLength(10)]
            : undefined
        }
        data-test-id="CustomerInformationForm-phoneNumber"
      />
      <Field
        name="customer.email"
        type="text"
        component={ValidationInput}
        label="Email"
        placeholder="eg. george.jones@superiorautos.com"
        validate={emailRequired(props) ? [required, emailValidation] : undefined}
        data-test-id="CustomerInformationForm-email"
      />
      <Field
        name="customer.confirmEmail"
        type="text"
        component={ValidationInput}
        label="Confirm"
        placeholder="eg. george.jones@superiorautos.com"
        validate={emailRequired(props) ? [required, match('customer.email')] : undefined}
        data-test-id="CustomerInformationForm-confirmEmail"
      />
      <Field name="customer.hasCoBuyer" component={ValidationCheckbox} label="I have a co-buyer" />
      {props.hasCoBuyer && (
        <Fragment>
          <Field
            name="customer.coBuyerFirstName"
            type="text"
            component={ValidationInput}
            label="First Name"
            placeholder="eg. Waylon"
          />
          <Field
            name="customer.coBuyerLastName"
            type="text"
            component={ValidationInput}
            label="Last Name"
            placeholder="eg. Jennings"
          />
          <Field
            name="customer.coBuyerEmail"
            type="text"
            component={ValidationInput}
            label="Email"
            placeholder="eg. waylon.jennings@worthycarriages.net"
            validate={[
              required,
              emailValidation,
              notMatch('customer.email', 'The co-buyer must have a separate email from the buyer'),
            ]}
          />
          <Field
            name="customer.coBuyerConfirmEmail"
            type="text"
            component={ValidationInput}
            label="Confirm"
            placeholder="eg. waylon.jennings@worthycarriages.net"
            validate={[required, match('customer.coBuyerEmail')]}
          />
        </Fragment>
      )}
      <Buttons {..._.omit(props, 'onSubmit')} data-test-name="CustomerInformationForm-Buttons" />
    </form>
  );
}

CustomerInformationForm.propTypes = {
  quoteType: QuoteTypePropType.isRequired,
  eSign: PropTypes.bool,
  email: PropTypes.string,
  phoneNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasCoBuyer: PropTypes.bool,
};

CustomerInformationForm.defaultProps = {
  eSign: false,
  email: null,
  phoneNumber: null,
  hasCoBuyer: false,
};

export const mapFromConnect = ({ eSign, newQuote, ...rest }) => {
  const quoteType = _.get(newQuote, 'form.quoteType');
  const eSignEmail = _.get(eSign, 'customer.email');
  const formEmail = customerInformationRateFormValueSelector(rest, 'customer.email');
  const formPhoneNumber = customerInformationRateFormValueSelector(rest, 'customer.phoneNumber');
  const hasCoBuyer = customerInformationRateFormValueSelector(rest, 'customer.hasCoBuyer');
  const initialValues = _.merge({}, customerInformationRateTestDataIfRequested(), {
    customer: {
      email: eSignEmail,
      confirmEmail: eSignEmail,
    },
  });

  return {
    hasCoBuyer,
    quoteType,
    eSign: eSignEmail ? !!eSignEmail : !!formEmail,
    email: formEmail,
    phoneNumber: formPhoneNumber,
    initialValues,
  };
};

export const addCoBuyerFlag = ({ initialValues, hasCoBuyer: hasCoBuyerFromForm }) => {
  // NOTE - Not in love with  could probably clean this up.
  const hasCoBuyer = (() => {
    if (!_.isNil(hasCoBuyerFromForm)) {
      return hasCoBuyerFromForm;
    } else {
      return !!(
        _.get(initialValues, 'customer.coBuyerFirstName') ||
        _.get(initialValues, 'customer.coBuyerLastName') ||
        _.get(initialValues, 'customer.coBuyerEmail') ||
        _.get(initialValues, 'customer.coBuyerConfirmEmail')
      );
    }
  })();
  return {
    hasCoBuyer,
    initialValues: _.merge({}, initialValues, {
      customer: {
        hasCoBuyer,
      },
    }),
  };
};

export default compose(
  connect(mapFromConnect),
  receiveValuesFromQueryData({
    customerFirstName: 'customer.firstName',
    customerLastName: 'customer.lastName',
    customerEmail: [
      {
        key: 'customer.email',
        useInitial: true,
      },
      {
        key: 'customer.confirmEmail',
        useInitial: true,
      },
    ],
    customerAddress: 'customer.address.street',
    customerCity: 'customer.address.city',
    customerState: 'customer.address.state',
    customerZip: 'customer.address.zipCode',
    customerPhone: 'customer.phoneNumber',
    coBuyerFirstName: 'customer.coBuyerFirstName',
    coBuyerLastName: 'customer.coBuyerLastName',
    coBuyerEmail: 'customer.coBuyerEmail',
    coBuyerConfirmEmail: 'customer.coBuyerConfirmEmail',
  }),
  withProps(addCoBuyerFlag),
  reduxForm({
    form: CUSTOMER_INFORMATION_RATE_FORM,
    validate: customerInformationRateValidator,
    asyncValidate: customerInformationRateFormAsyncValidation,
    asyncBlurFields: ['customer.email', 'customer.coBuyerEmail'],
  }),
)(CustomerInformationForm);
