import React, { useEffect, useState } from 'react';
import { isEmpty, omit } from 'lodash';

const LEAVE_MESSAGE =
  'If you leave, the quote form you filled will reset. Are you sure you want to leave?';

let haveUnsavedData = false;
const unloadHandler = event => {
  if (!haveUnsavedData) return undefined;

  event.preventDefault();
  event.returnValue = LEAVE_MESSAGE;
  return LEAVE_MESSAGE;
};

export const unregisterUnloadHandler = (resetUnsavedDataFlag = false) => {
  if (resetUnsavedDataFlag) haveUnsavedData = false;
  window.removeEventListener('beforeunload', unloadHandler, { capture: true });
};

export const registerUnloadHandler = () => {
  unregisterUnloadHandler();
  window.addEventListener('beforeunload', unloadHandler, { capture: true });
  return unregisterUnloadHandler;
};

export const WithUnloadHandler =
  Component =>
  ({ vehicleFormValues, router, route, ...passThroughProps }) => {
    const [wereHere, setWereHere] = useState(false);
    useEffect(() => {
      if (vehicleFormValues) setWereHere(true);
    }, [wereHere, vehicleFormValues]);

    useEffect(() => {
      haveUnsavedData = !isEmpty(
        omit(vehicleFormValues, ['commercialType', 'vehicleCondition', 'quoteType']),
      );

      const cancelRouterPrompt = router.setRouteLeaveHook(route, ({ pathname }) => {
        if (pathname.startsWith('/quote/')) return true;

        if (!haveUnsavedData) {
          unregisterUnloadHandler();
          return true;
        }

        if (window.confirm(LEAVE_MESSAGE)) {
          unregisterUnloadHandler();
          return true;
        }

        return false;
      });

      return () => {
        haveUnsavedData = false;
        cancelRouterPrompt();
      };
    }, [router, route, vehicleFormValues]);

    return (
      <Component
        registerUnloadHandler={registerUnloadHandler}
        router={router}
        route={route}
        vehicleFormValues={vehicleFormValues}
        {...passThroughProps}
      />
    );
  };

const steps = ['/quote/eula', '/quote/vehicle', '/quote/plans', '/quote/rate'];

export const InterceptNavigation = ({
  router,
  route,
  location,
  blockForwardAttempt = false,
  onForwardBlocked,
}) => {
  useEffect(() => {
    if (!router || !route || !location) return undefined;

    const currentPathname = location.pathname;

    return router.setRouteLeaveHook(route, ({ pathname: nextPathname }) => {
      if (nextPathname.startsWith('/quote/')) {
        const isForwardNavigation = steps.indexOf(currentPathname) < steps.indexOf(nextPathname);

        if (isForwardNavigation && blockForwardAttempt) {
          if (onForwardBlocked) onForwardBlocked();
          return false;
        } else {
          return true;
        }
      } else {
        if (haveUnsavedData && !window.confirm(LEAVE_MESSAGE)) {
          return false;
        }

        unregisterUnloadHandler();
        return true;
      }
    });
  }, [router, route, location, blockForwardAttempt, onForwardBlocked]);

  return <div />;
};
