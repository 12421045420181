import { castArray } from 'lodash';
import { sanitizeStrFromCenter, normalizeNumberFormat } from './common';

const normalizeNumberMoney = normalizeNumberFormat('0.00');

const collapseNumberMoney = (str, sanitizeOptions) => {
  if (!str) return str;

  if (!sanitizeOptions) return normalizeNumberMoney(str);

  const sanitize = sanitizeStrFromCenter(strPart => strPart.replace(/[^\d.]/g, ''));
  return sanitize(str, sanitizeOptions);
};

export const collapseNumberMoneyHideZeroCents = (str, sanitizeOptions) => {
  const [newValue, ...rest] = castArray(collapseNumberMoney(str, sanitizeOptions));

  if (sanitizeOptions || !newValue || (newValue && !newValue.endsWith('.00')))
    return [newValue, ...rest];

  return [newValue.substring(0, newValue.length - 3)];
};
