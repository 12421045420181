import {
  quoteTypeIncludesGAP,
  quoteTypeIncludesTWP,
  quoteTypeIncludesVSC,
} from '../../constants/quoteType';

export const isAllPlansSelected = newQuote => {
  const { vehicleFormValues, plans, selectedVscQuoteId, selectedGapQuoteId, selectedTwpQuoteId } =
    newQuote;

  if (!vehicleFormValues || !plans) return false;

  const { quoteType } = vehicleFormValues;

  if (!quoteType || quoteType.length === 0) return false;

  if (quoteTypeIncludesVSC(quoteType) && !selectedVscQuoteId) return false;

  if (quoteTypeIncludesGAP(quoteType) && !selectedGapQuoteId) return false;

  if (quoteTypeIncludesTWP(quoteType) && !selectedTwpQuoteId) return false;

  return true;
};
