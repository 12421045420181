import React, { useCallback, useEffect, useState } from 'react';
import { flatMapDeep, isEmpty, isObject, startCase } from 'lodash';
import { Button, ProgressBar } from 'react-bootstrap';
import { formikSetErrorFieldsTouchedHelper } from '../../../utils/formikSetFieldsHelper';

const flattenErrors = (errors, p = '') =>
  flatMapDeep(errors, (i, k) =>
    isObject(i) ? flattenErrors(i, `${p} ${k}`) : `${startCase(`${p} ${k}`)}: ${i}`,
  );

export default function Submit({
  loadingText = 'Submitting...',
  children,
  style,
  errors,
  setTouched,
  ...props
}) {
  const { isValid, isSubmitting } = props;

  const [showErrors, setShowErrors] = useState();

  const onClickWrapped = useCallback(() => {
    setShowErrors(showErrors || true);
    formikSetErrorFieldsTouchedHelper({ setTouched })(errors);
  }, [showErrors, errors, setTouched]);

  useEffect(() => {
    if (!errors || isEmpty(errors)) setShowErrors(undefined);
  }, [errors]);

  return (
    <>
      <Button
        className="btn btn-primary"
        type="submit"
        disabled={isSubmitting}
        style={{
          position: 'relative',
          ...style,
        }}
        onClick={onClickWrapped}
        {...props}
      >
        {isSubmitting && (
          <ProgressBar
            active
            now={100}
            style={{ position: 'absolute', inset: 0, height: '100%', opacity: 0.6 }}
          />
        )}
        <span>{isSubmitting ? loadingText : children}</span>
      </Button>

      {!isValid && !!showErrors && (
        <div>
          <div>Oops, looks like there are some validation errors in the form!</div>
          <div>Please review the form and fix the errors to continue.</div>
          {flattenErrors(errors).map(str => (
            <div key={str} className="text-danger">
              {str}
            </div>
          ))}
        </div>
      )}
    </>
  );
}
