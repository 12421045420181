import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Grid, Row, Col, ProgressBar, Alert } from 'react-bootstrap';
import BrowserWarning from '../../components/BrowserWarning';
import {
  getIssuedContract,
  downloadIssuedContract,
  getIssuedContractTasksIds,
} from '../../redux/modules/issuedContract';
import { filenameForDownload } from '../all-quotes/helpers/download';
import { contractTypesAll, contractTypeTitle } from '../../constants/contractType';
import { TaskProgressPanel } from '../Tasks/ProgressPanel';
import { stepsDescs } from '../Tasks/IssuedContract';
import { useTaskEvents } from '../Tasks/useTaskEvents';

const CUSTOMER_SATISFACTION_URL =
  'http://www.123contactform.com/sfnew2.php?redirect=true&s=form-1362402&control11782294=';

// Result can be either IssuedContract record,
// or a result of contract issue process, which contains the record, sub statuses and process ID.
const readResult = issueContractResult => {
  if (_.has(issueContractResult, 'issuedContract._id')) {
    const { issuedContract, subStatuses = [], issueContractProcessId } = issueContractResult;

    return {
      issuedContract,
      subStatuses,
      issueContractProcessId,
    };
  } else if (_.has(issueContractResult, '_id')) {
    return {
      issuedContract: issueContractResult,
      subStatuses: [],
    };
  } else {
    return {};
  }
};

const IssueTaskProgress = ({ taskId }) => {
  const { taskEvents, errorIshEvents } = useTaskEvents(taskId);

  const hasErrors = errorIshEvents.length > 0;

  return (
    <TaskProgressPanel
      stepsDescs={stepsDescs}
      taskEvents={taskEvents}
      defaultExpanded={hasErrors ? true : undefined}
    />
  );
};

export function ContractIssueComplete(props) {
  const [loading, setLoading] = useState(false);
  const [issuedContract, setIssuedContract] = useState();
  const [subStatuses, setSubStatuses] = useState();
  const [issueContractProcessId, setIssueContractProcessId] = useState();

  const contractId = _.get(props, 'params.id');
  const [taskIds, setTaskIds] = useState();

  useEffect(() => {
    let cancelled = false;

    const load = async () => {
      const taskIds = await props.getIssuedContractTasksIds(contractId);
      if (!cancelled) setTaskIds(taskIds);
    };

    load();

    return () => {
      cancelled = true;
    };
  }, [props.getIssuedContractTasksIds, contractId]);

  const [taskId] = taskIds || [];

  useEffect(() => {
    const { params, issueContractResult } = props;

    const paramsId = _.get(params, 'id');

    const {
      issuedContract,
      subStatuses = [],
      issueContractProcessId,
    } = readResult(issueContractResult);

    if (issuedContract) {
      if (paramsId && issuedContract._id !== paramsId) {
        loadIssuedContract(paramsId);
      } else {
        setIssuedContract(issuedContract);
        setSubStatuses(subStatuses);
        setIssueContractProcessId(issueContractProcessId);
      }
    } else if (paramsId) {
      loadIssuedContract(paramsId);
    } else {
      setIssuedContract(null);
    }
  }, []);

  const loadIssuedContract = id => {
    setLoading(true);
    props.getIssuedContract(id).then(issuedContract => {
      setIssuedContract(issuedContract);
      setLoading(false);
    });
  };

  const handleDownloadClick = (contractType, testId) => () => {
    const { downloadIssuedContract } = props;

    downloadIssuedContract(
      issuedContract._id,
      _.toLower(contractType),
      filenameForDownload(issuedContract, contractType),
      { testId },
    );
  };

  const { dealerName } = props;

  if (issuedContract === undefined || loading) return <ProgressBar striped active now={100} />;

  if (issuedContract === null)
    return (
      <Grid data-test-id="ContractIssueComplete">
        <Alert bsStyle="warning">
          <p>Contract not found</p>
        </Alert>
      </Grid>
    );

  const isESign = _.get(issuedContract, 'eSign.id');
  return (
    <Grid data-test-id="ContractIssueComplete">
      {isESign && (
        <Row className="margin-bottom-40px">
          <Col xs={12}>
            <h2>
              <a
                href={_.get(issuedContract, 'eSign.url')}
                target="_blank"
                rel="noopener noreferrer"
                data-test-id="ContractIssueComplete-eSign-link"
                className="btn btn-primary"
              >
                Click here to sign your documents via E-Signature, now.
              </a>{' '}
            </h2>
            <p>Or, you will receive details and a link for E-signature via email.</p>
          </Col>
        </Row>
      )}
      {!isESign && (
        <Fragment>
          <Row>
            <Col xs={12}>
              <h2>ePRco Contract Link(s)</h2>
              <b>Click link(s) to open PDF documents in another tab or window.</b>
              <p>
                After the new tab or window opens, choose "Print" to print the Contract on letter
                size paper. Have the first page(s) signed by the customer and yourself and send them
                to PRco to finalize the Contracts. The data has already been recorded for billing
                purposes if the documents are not marked as a "DEMO."
              </p>
            </Col>
          </Row>
          <Row className="margin-bottom-40px">
            <Col xs={12} data-test-id="ContractIssueComplete-contracts">
              {contractTypesAll
                .filter(contractType => issuedContract[`${_.toLower(contractType)}Contract`])
                .map(contractType => (
                  <h4 key={contractType}>
                    <a
                      onClick={handleDownloadClick(
                        contractType,
                        `ContractIssueComplete-contracts-download-${contractType}`,
                      )}
                      data-test-type="ContractIssueComplete-contracts-link"
                      data-contract-type={contractType}
                    >
                      {contractTypeTitle(contractType)} Contract (PDF)
                    </a>
                  </h4>
                ))}
            </Col>
          </Row>
        </Fragment>
      )}
      {taskId && (
        <Row>
          <Col xs={12}>
            <IssueTaskProgress taskId={taskId} />
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={12}>
          <p>
            If you see received an error or warning, please use the Live Chat or Email PRco button
            in the lower right so we can help resolve any problems.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <h4>
            <a
              href={`${CUSTOMER_SATISFACTION_URL}${dealerName}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Customer Satisfaction Survey
            </a>
          </h4>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {(subStatuses || []).map(({ message, success = true, eventTimestamp }, index) => (
            <Alert
              key={`subStatus-${index}`}
              className={success ? 'alert-success' : 'alert-danger'}
            >
              {message}
              {eventTimestamp && (
                <div>
                  <small>Event timestamp: {eventTimestamp}</small>
                </div>
              )}
            </Alert>
          ))}
          {(subStatuses || []).length > 0 && issueContractProcessId && (
            <div>
              <small>Process ID: {issueContractProcessId}</small>
            </div>
          )}
          <BrowserWarning />
        </Col>
      </Row>
    </Grid>
  );
}

ContractIssueComplete.propTypes = {
  dealerName: PropTypes.string,
  params: PropTypes.object.isRequired,
  issueContractResult: PropTypes.object.isRequired,
  getIssuedContract: PropTypes.func.isRequired,
  downloadIssuedContract: PropTypes.func.isRequired,
};

ContractIssueComplete.defaultProps = {
  dealerName: null,
};

export default connect(
  ({ issuedContract, dealer }) => ({
    issueContractResult: _.get(issuedContract, 'result', {}),
    dealerName: _.get(dealer, 'name'),
  }),
  {
    getIssuedContract,
    downloadIssuedContract,
    getIssuedContractTasksIds,
  },
)(ContractIssueComplete);
