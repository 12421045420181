import { find, omit, chain, flow, get, isEqual, isEmpty } from 'lodash';
import { loadingCycle } from './page';
import customSelector from '../../utils/selector';
import rest from '../../utils/service';

const TOGGLE_EULA_AGREEMENT = 'redux/newQuote/TOGGLE_EULA_AGREEMENT';
const SET_QUOTE_INITIALIZATION = 'redux/newQuote/SET_QUOTE_INITIALIZATION';
const STORE_VEHICLE_FORM_VALUES = 'redux/newQuote/STORE_VEHICLE_FORM_VALUES';
const STORE_VEHICLE_FORM_VALUES_SAVED = 'redux/newQuote/STORE_VEHICLE_FORM_VALUES_SAVED';
const STORE_PLANS = 'redux/newQuote/STORE_PLANS';
const STORE_RATE_FORM_VALUES = 'redux/newQuote/STORE_RATE_FORM_VALUES';
const STORE_NEW_QUOTE_ERROR = 'redux/newQuote/STORE_NEW_QUOTE_ERROR';
const STORE_VSC_QUOTE_PLAN_SELECTION = 'redux/newQuote/STORE_VSC_QUOTE_PLAN_SELECTION';
const STORE_VSC_EXTRA_COVERAGE_SELECTION = 'redux/newQuote/STORE_VSC_EXTRA_COVERAGE_SELECTION';
const STORE_EXTRA_COVERAGE_SELECTION = 'redux/newQuote/STORE_EXTRA_COVERAGE_SELECTION';
const CLEAR_VSC_EXTRA_COVERAGE_SELECTION = 'redux/newQuote/CLEAR_EXTRA_COVERAGE_SELECTION';
const CLEAR_ALL_COVERAGE_SELECTION = 'redux/newQuote/CLEAR_ALL_COVERAGE_SELECTION';
const STORE_GAP_QUOTE_PLAN_SELECTION = 'redux/newQuote/STORE_GAP_QUOTE_PLAN_SELECTION';
const STORE_TWP_QUOTE_PLAN_SELECTION = 'redux/newQuote/STORE_TWP_QUOTE_PLAN_SELECTION';
const SET_SIGNATURE_FLOW = 'redux/newQuote/SET_SIGNATURE_FLOW';
const CLEAR_STATE = 'redux/newQuote/CLEAR_STATE';

const clearCoverage = {
  plans: undefined,
  selectedGapQuoteId: undefined,
  selectedTwpQuoteId: undefined,
  selectedVscQuoteId: undefined,
  extraCoverages: {},
};

const initialState = {
  eula: false,
  quoteInitialization: undefined,
  vehicleFormValues: undefined,
  vehicleFormValuesSaved: false,
  ...clearCoverage,
  rateFormValues: undefined,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_QUOTE_INITIALIZATION:
      return {
        ...state,
        quoteInitialization: payload,
      };
    case TOGGLE_EULA_AGREEMENT:
      return {
        ...state,
        eula: payload,
      };
    case STORE_VEHICLE_FORM_VALUES:
      const vehicleFormValuesEqual = isEqual(state.vehicleFormValues, payload);
      return {
        ...state,
        vehicleFormValues: payload,
        vehicleFormValuesSaved: vehicleFormValuesEqual ? state.vehicleFormValuesSaved : false,
        ...(!vehicleFormValuesEqual ? clearCoverage : {}),
      };
    case STORE_VEHICLE_FORM_VALUES_SAVED:
      return {
        ...state,
        vehicleFormValuesSaved: !!payload,
      };
    case STORE_PLANS:
      return {
        ...state,
        plans: payload,
        error: null,
      };
    case STORE_VSC_QUOTE_PLAN_SELECTION:
      return {
        ...state,
        selectedVscQuoteId: payload,
      };
    case STORE_GAP_QUOTE_PLAN_SELECTION:
      return {
        ...state,
        selectedGapQuoteId: payload,
      };
    case STORE_TWP_QUOTE_PLAN_SELECTION:
      return {
        ...state,
        selectedTwpQuoteId: payload,
      };
    case STORE_VSC_EXTRA_COVERAGE_SELECTION:
      return {
        ...state,
        extraCoverages: payload,
      };
    case CLEAR_VSC_EXTRA_COVERAGE_SELECTION:
      return {
        ...state,
        extraCoverages: {},
      };
    case CLEAR_ALL_COVERAGE_SELECTION:
      return {
        ...state,
        ...clearCoverage,
      };
    case STORE_RATE_FORM_VALUES:
      const vehicleFormValuesUpdate = {};
      const { gapRate: { kelleyBlueBook } = {}, vscRate: { vehiclePrice } = {} } = payload;
      if (kelleyBlueBook) vehicleFormValuesUpdate.kelleyBlueBook = kelleyBlueBook;
      if (vehiclePrice) vehicleFormValuesUpdate.vehiclePurchasePrice = vehiclePrice;

      return {
        ...state,
        rateFormValues: payload,
        vehicleFormValues: isEmpty(vehicleFormValuesUpdate)
          ? state.vehicleFormValues
          : {
              ...state.vehicleFormValues,
              ...vehicleFormValuesUpdate,
            },
      };
    case STORE_NEW_QUOTE_ERROR:
      return {
        ...state,
        error: payload,
      };

    case SET_SIGNATURE_FLOW:
      return {
        ...state,
        signatureFlow: payload,
      };
    case CLEAR_STATE:
      return {
        eula: false,
        quoteInitialization: undefined,
        vehicleFormValues: undefined,
        vehicleFormValuesSaved: false,
        ...clearCoverage,
        rateFormValues: undefined,
      };
    default:
      return state;
  }
};

export const toggleEulaAgreement = (payload = false) => ({
  type: TOGGLE_EULA_AGREEMENT,
  payload,
});

export const setQuoteInitialization = payload => ({
  type: SET_QUOTE_INITIALIZATION,
  payload,
});

export const storeVehicleFormValues = payload => ({
  type: STORE_VEHICLE_FORM_VALUES,
  payload,
});

export const storeVehicleFormValuesSaved = (payload = true) => ({
  type: STORE_VEHICLE_FORM_VALUES_SAVED,
  payload,
});

export const storeNewQuoteError = error => ({
  type: STORE_NEW_QUOTE_ERROR,
  payload: get(error, 'response.data.message', get(error, 'message')),
});

export const submitNewQuoteRequest = request => async (dispatch, getState) => {
  try {
    const { dealer } = getState();
    const dealerState = _.get(dealer, 'details.address.state');
    if (dealerState === 'CA') {
      delete request.financedAmountLessCostOfGap;
    }
    const { data } = await loadingCycle(
      rest.get('quotes', {
        params: {
          ...request,
          dealerLot: get(dealer, 'details.dealerLot', 0),
        },
      }),
    );
    dispatch({
      type: STORE_PLANS,
      payload: omit(data, 'raw'),
    });
  } catch (err) {
    dispatch(storeNewQuoteError(err));
  }
};

export const storeVscQuotePlanSelection = payload => ({
  type: STORE_VSC_QUOTE_PLAN_SELECTION,

  payload,
});

/** @deprecated old form action, can be deleted? */
export const storeExtraCoverageSelection = payload => ({
  type: STORE_EXTRA_COVERAGE_SELECTION,
  payload,
});

/** @deprecated old form action, can be deleted? */
export const clearExtraCoverageSelection = () => ({
  type: CLEAR_VSC_EXTRA_COVERAGE_SELECTION,
});

export const storeVSCExtraCoverageSelection = payload => ({
  type: STORE_VSC_EXTRA_COVERAGE_SELECTION,
  payload,
});

export const clearVSCExtraCoverageSelection = () => ({
  type: CLEAR_VSC_EXTRA_COVERAGE_SELECTION,
});

export const storeGapQuotePlanSelection = payload => ({
  type: STORE_GAP_QUOTE_PLAN_SELECTION,
  payload,
});

export const storeTwpQuotePlanSelection = payload => ({
  type: STORE_TWP_QUOTE_PLAN_SELECTION,
  payload,
});

export const clearAllCoverageSelection = () => ({
  type: CLEAR_ALL_COVERAGE_SELECTION,
});

export const storeRateFormValues = payload => ({
  type: STORE_RATE_FORM_VALUES,
  payload,
});

export const setSignatureFlow = payload => ({
  type: SET_SIGNATURE_FLOW,
  payload,
});

export const clearState = () => ({
  type: CLEAR_STATE,
});

/* redux selectors */
const groupedQuotes = ({ newQuote }) => _.get(newQuote, 'plans.groupedRatingQuotes');
const flattenGroupedQuotes = groupedRatingQuotes =>
  chain(groupedRatingQuotes).map('data').flatten().value();
const vscQuoteId = ({ newQuote }) => newQuote.selectedVscQuoteId;
const gapQuoteId = ({ newQuote }) => newQuote.selectedGapQuoteId;
const twpQuoteId = ({ newQuote }) => newQuote.selectedTwpQuoteId;

export const selectedGapQuote = customSelector(
  gapQuoteId,
  flow(groupedQuotes, flattenGroupedQuotes),
  (quoteId, q) => find(q, { quoteId }),
);

export const selectedTwpQuote = customSelector(
  twpQuoteId,
  flow(groupedQuotes, flattenGroupedQuotes),
  (quoteId, q) => find(q, { quoteId }),
);

export const selectedVscQuote = customSelector(
  vscQuoteId,
  flow(groupedQuotes, flattenGroupedQuotes),
  (quoteId, q) => find(q, { quoteId }),
);

export const vehicleYear = ({ newQuote }) =>
  get(newQuote, ['plans', 'ratingQuotes', 0, 'vehicleYear']);
