import React from 'react';
import DateTime from 'react-datetime';
import { useField } from 'formik';
import _ from 'lodash';
import { ControlLabel, FormGroup } from 'react-bootstrap';

const DATE_FORMAT = 'MM/DD/YYYY';

export function DateCalendar({ label = '', placeholder = '', disabled, ...props }) {
  const [field, meta, helpers] = useField(props); // eslint-disable-line no-unused-vars
  const { value: currentValue } = meta;
  const { setValue } = helpers;

  const handleChange = momentDate => {
    if (!_.isString(momentDate) && _.isFunction(_.get(momentDate, 'format'))) {
      setValue(momentDate.format(DATE_FORMAT));
    }
  };

  return (
    <FormGroup>
      {label && <ControlLabel>{label}</ControlLabel>}
      <DateTime
        timeFormat={false}
        dateFormat={DATE_FORMAT}
        value={currentValue}
        onChange={handleChange}
        inputProps={{
          placeholder,
          disabled,
          ...field,
        }}
      />
      {meta.touched && meta.error && <span className="text-danger">{meta.error}</span>}
    </FormGroup>
  );
}
