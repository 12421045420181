import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { startCase, map, chain } from 'lodash';
import { Grid, Row, Table, Button } from 'react-bootstrap';
import TemplateWizard from '../modals/upload-templates/TemplateWizard';
import EditFieldsModal from '../modals/edit-fields/EditFieldsModal';
import TemplateSearchForm from './TemplateSearchForm';
import { getTemplates, deleteTemplate, viewTemplate } from '../../../redux/modules/eSignAdmin';
import { STATES_ABBREVIATIONS } from '../../../constants/general';
import { contractTypesAll } from '../../../constants/contractType';

const toState = ({ eSignAdmin }) => ({
  templateList: eSignAdmin.templateList,
});

const FIELDS = [
  'name',
  'templateType',
  'state',
  'language',
  'contractYear',
  'lenderMatch',
  'created',
];

export function Templates(props) {
  const [confirmDownload, setConfirmDownload] = useState(false);
  const [downloadInfo, setDownloadInfo] = useState(null);
  useEffect(() => {
    props.getTemplates();
  }, []);

  const valueFormatter = (value = '', key) => {
    switch (key) {
      case 'created':
        return moment(new Date(value)).format('MMMM Do, YYYY h:mm a');
      case 'language':
        if (['english', 'spanish'].includes(value)) return value.toUpperCase();
        return <span style={{ color: 'darkred' }}>{value}</span>;
      case 'state':
        if (STATES_ABBREVIATIONS.includes(value)) return value;
        return <span style={{ color: 'darkred' }}>{value}</span>;
      case 'templateType':
        if (contractTypesAll.includes(value)) return value;
        return <span style={{ color: 'darkred' }}>{value}</span>;
      case 'contractYear':
        if (/^\d{4}[A-Z]$/.test(value)) return value;
        return <span style={{ color: 'darkred' }}>{value}</span>;
      default:
        return value;
    }
  };

  const handleConfirmDownload = () => {
    props.viewTemplate(downloadInfo.id, downloadInfo.name);
    setConfirmDownload(false);
    setDownloadInfo(null);
  };

  const { templateList } = props;
  return (
    <React.Fragment>
      <Grid>
        <Row>
          <TemplateSearchForm />
        </Row>
        {confirmDownload && (
          <Row style={{ margin: 8 }}>
            <div>Download {downloadInfo && downloadInfo.name}?</div>
            <Button
              bsStyle="default"
              onClick={() => {
                setConfirmDownload(false);
                setDownloadInfo(null);
              }}
            >
              Cancel
            </Button>
            {'  '}
            <Button bsStyle="primary" onClick={handleConfirmDownload}>
              Download
            </Button>
          </Row>
        )}
        <Row>
          <Table striped bordered condensed responsive hover>
            <thead>
              <tr>
                {FIELDS.map(f => (
                  <td key={f}>{startCase(f)}</td>
                ))}
                <td>{/* Delete column has no border on header without */}</td>
              </tr>
            </thead>
            <tbody>
              {chain(templateList)
                .sortBy(['templateType', 'state', 'language', 'lenderMatch'])
                .map((row, k) => (
                  <tr
                    key={k}
                    title="click to view"
                    onClick={() => {
                      setConfirmDownload(true);
                      setDownloadInfo({ id: row._id, name: row.name });
                    }}
                  >
                    {map(FIELDS, field => (
                      <td key={field}>{valueFormatter(row[field], field)}</td>
                    ))}
                    <td title="">
                      <Button
                        bsStyle="danger"
                        className="delete-template"
                        onClick={e => {
                          e.stopPropagation();
                          props.deleteTemplate(row._id);
                        }}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))
                .value()}
            </tbody>
          </Table>
        </Row>
        <TemplateWizard />
        <EditFieldsModal />
      </Grid>
    </React.Fragment>
  );
}

export default connect(toState, { getTemplates, deleteTemplate, viewTemplate })(Templates);
