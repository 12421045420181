import React from 'react';
import { Collapse, Well } from 'react-bootstrap';
import { connect } from 'react-redux';
import _ from 'lodash';
import DOMPurify from 'dompurify';

export const RIDE_SHARING_MESSAGE = 'RIDE_SHARING_MESSAGE';

/* eslint-disable react/no-danger */
const RideSharingMessage = ({ show, message }) => (
  <Collapse in={show && !!message?.text?.trim()}>
    <Well>
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(_.get(message, 'text')) }} />
    </Well>
  </Collapse>
);
/* eslint-enable react/no-danger */

export default connect(({ messaging }) => ({
  message: _.find(messaging.data, { name: RIDE_SHARING_MESSAGE }),
}))(RideSharingMessage);
