import _ from 'lodash';
import moment from 'moment';

const isEmpty = value => value === undefined || value === null || value === '';
const join = rules => (value, data, params) =>
  rules.map(rule => rule(value, data, params)).filter(error => !!error)[0];

export function email(value) {
  // Let's not start a debate on email regex. This is just for an example app!
  if (!isEmpty(value) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return 'Invalid email address';
  }
}

export function required(value) {
  if (isEmpty(value)) {
    return 'Required';
  }
}

export function minLength(min) {
  return value => {
    if (!isEmpty(value) && value.length < min) {
      return `Must be at least ${min} characters`;
    }
  };
}

export function maxLength(max) {
  return value => {
    if (!isEmpty(value) && value.length > max) {
      return `Must be no more than ${max} characters`;
    }
  };
}

export function minNumber(min) {
  return value => {
    if (!isEmpty(value) && parseFloat(value) < min) {
      return `Must be no lower than ${min}`;
    }
  };
}

export function maxNumber(max) {
  return value => {
    if (!isEmpty(value) && parseFloat(value) > max) {
      return `Must be no higher than ${max}`;
    }
  };
}

export function exactLength(len) {
  return value => {
    if (!isEmpty(value) && value.length > len) {
      return `Must be exactly ${len} characters`;
    }
  };
}

export function integer(value) {
  if (!isEmpty(value) && !Number.isInteger(Number(value))) {
    return 'Must be an integer';
  }
}

// note: PRCO database only accepts phone numbers in 10 digit integer format.
export function integerPhoneNumber(value) {
  if (integer(value)) {
    return 'Phone number cannot contain spaces, dashes, or parenthesis. Numbers only.';
  }
}

export function money(value) {
  if (!isEmpty(value) && parseFloat(value) <= 0) {
    return 'Must be a valid US currency value (more than 0.00).';
  }
  if (!isEmpty(value) && !/^\d+(\.\d{0,2})?$/.test(value)) {
    return 'Must be a valid US currency value';
  }
}

export function oneOf(enumeration) {
  return value => {
    if (!~enumeration.indexOf(value)) {
      return `Must be one of: ${enumeration.join(', ')}`;
    }
  };
}

export function match(field, message = 'Confirmation must match') {
  return (value, data) => {
    if (data) {
      if (value !== _.get(data, field)) {
        return message;
      }
    }
  };
}

export function notMatch(field, message = 'Must not match') {
  return (value, data) => {
    if (data) {
      if (value === _.get(data, field)) {
        return message;
      }
    }
  };
}

export function notBeforeDays(days = 30) {
  return value => {
    if (value && moment().diff(moment(value), 'days') > days) {
      return `Your date must be within last ${days} days`;
    }
  };
}

export function notInFuture(value) {
  const difference = value && moment().diff(moment(value), 'days', true);
  if (difference < 0) {
    return 'Your date cannot be in the future';
  }
}

export function isValidDate(format = 'MM/DD/YYYY') {
  return value => {
    if (!value || !moment(value, format, true).isValid()) {
      return `The date entered is an invalid format. Please enter your date as ${format}`;
    }
  };
}

const NUMBERS_SYMBOLS = /[\d!@#$%^&*()_+={}[\]|\\/;:",]/;
export function isOnlyAlphabetWithSymbols(value) {
  const hasNumbersSymbols = value && NUMBERS_SYMBOLS.test(value);
  if (hasNumbersSymbols) {
    return 'Your entry cannot contain anything but letters, spaces, or these symbols: "-.\'"';
  }
}

const IS_ZIP = /^\d{5}(-\d{4})?$/;
export function isZipCode(value) {
  const isZip = value && IS_ZIP.test(value);
  if (!isZip) {
    return 'You must pass a valid zip-code, ex: 20002 or 20002-1234';
  }
}

export function createValidator(rules, params) {
  return (data = {}) => {
    const errors = {};
    Object.keys(rules).forEach(key => {
      const rule = join([].concat(rules[key])); // concat enables both functions and arrays of functions
      const error = rule(_.get(data, key), data, { key, ...params });
      if (error) {
        _.set(errors, key, error);
      }
    });
    return errors;
  };
}
