import React from 'react';
import { ControlLabel, FormGroup, Row, Col } from 'react-bootstrap';
import { Field, useField } from 'formik';
import { contractTypeTitle as getContractTypeTitle } from '../../../../constants/contractType';
import { filterQuoteTypesOnDealerInfo } from '../../../new-quote/QuoteTypeInput';

export default function ContractTypeCheckboxes({ dealerInfo, ...props }) {
  const [field, meta] = useField(props); // eslint-disable-line no-unused-vars
  return (
    <FormGroup>
      <Row>
        <ControlLabel>Contract Type</ControlLabel>
      </Row>
      <Row>
        {filterQuoteTypesOnDealerInfo(dealerInfo).map(ct => (
          <Col xs={4} className="pl-0" key={ct}>
            <label>
              <Field type="checkbox" {...props} className="mr-2" key={ct} value={ct} />
              {getContractTypeTitle(ct)}
            </label>
          </Col>
        ))}
        {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
      </Row>
    </FormGroup>
  );
}
