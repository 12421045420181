import React, { useEffect } from 'react';
import { isUndefined, omitBy } from 'lodash';
import PropTypes from 'prop-types';
import { ProgressBar } from 'react-bootstrap';
import { connect } from 'react-redux';
import { clearAllMessages, clearAllErrors } from '../../redux/modules';
import { getDealer } from '../../redux/modules/dealer';
import { getDealerSettings } from '../../redux/modules/dealerSettings';
import { setQuoteInitialization } from '../../redux/modules/newQuote';
import { useStoreFormInQuery } from '../../components/common/query/hooks';

export function NewQuote(props) {
  const {
    router,
    location,
    clearAllErrors,
    clearAllMessages,
    setQuoteInitialization,
    getDealer,
    getDealerSettings,
  } = props;

  const [valuesFromQuery] = useStoreFormInQuery(router, location);

  useEffect(() => {
    const quoteTypeFromQuery = valuesFromQuery.quoteType;
    const quoteType = quoteTypeFromQuery ? quoteTypeFromQuery.split(',') : undefined;
    setQuoteInitialization(omitBy({ ...valuesFromQuery, quoteType }, isUndefined));
    getDealer();
    getDealerSettings();
    clearAllErrors();
    clearAllMessages();

    router.replace('/quote/eula');
  }, [setQuoteInitialization, valuesFromQuery]);

  return (
    <div className="container">
      <ProgressBar />
    </div>
  );
}

NewQuote.propTypes = {
  location: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  setQuoteInitialization: PropTypes.func.isRequired,
};

export default connect(() => ({}), {
  setQuoteInitialization,
  getDealer,
  getDealerSettings,
  clearAllErrors,
  clearAllMessages,
})(NewQuote);
