import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { isEmpty } from 'lodash';
import Eula from './Eula';
import { clearAllMessages, clearAllErrors } from '../../redux/modules';
import { getDealer } from '../../redux/modules/dealer';
import { getDealerSettings } from '../../redux/modules/dealerSettings';
import {
  setQuoteInitialization,
  toggleEulaAgreement,
  clearAllCoverageSelection,
} from '../../redux/modules/newQuote';
import { InterceptNavigation, WithUnloadHandler } from './WithUnloadHandler';

export function QuoteEULA(props) {
  const {
    router,
    route,
    location,
    registerUnloadHandler,
    quoteInitialization,
    setQuoteInitialization,
    getDealer,
    getDealerSettings,
    toggleEulaAgreement,
    clearAllCoverageSelection,
  } = props;

  useEffect(() => {
    if (!quoteInitialization) {
      getDealer();
      getDealerSettings();
      setQuoteInitialization({});
      clearAllErrors();
      clearAllMessages();
    }
  }, []);

  const handleSubmit = () => {
    registerUnloadHandler();
    toggleEulaAgreement(true);
    router.push(`/quote/vehicle`);
  };

  return (
    <div className="container">
      <Helmet title="New Quote" />
      <Eula onSubmit={handleSubmit} onDealerChange={clearAllCoverageSelection} />
      <InterceptNavigation router={router} route={route} location={location} />
      {!isEmpty(quoteInitialization) && <Alert color="info">Form pre-filled</Alert>}
    </div>
  );
}

QuoteEULA.propTypes = {
  quoteInitialization: PropTypes.object.isRequired,
  clearAllCoverageSelection: PropTypes.func.isRequired,
};

export default compose(
  connect(
    ({ newQuote: { quoteInitialization, vehicleFormValues } }) => ({
      quoteInitialization,
      vehicleFormValues,
    }),
    {
      setQuoteInitialization,
      getDealer,
      getDealerSettings,
      toggleEulaAgreement,
      clearAllCoverageSelection,
      clearAllErrors,
      clearAllMessages,
    },
  ),
  WithUnloadHandler,
)(QuoteEULA);
