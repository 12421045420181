import React, { useMemo } from 'react';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import { useField } from 'formik';

export default function Select({
  label = '',
  description = '',
  options: optionsRaw,
  disabled,
  ...props
}) {
  const [field, meta, helpers] = useField(props); // eslint-disable-line no-unused-vars
  const { value: currentValue } = meta;
  const { setValue } = helpers;

  const handleChange = e => setValue(e.target.value);

  const options = useMemo(
    () =>
      optionsRaw?.map(item => {
        if (typeof item === 'string') return { value: item, label: item };
        return item;
      }),
    [optionsRaw],
  );

  const currentUnlisted = useMemo(
    () => !options?.map(v => v.value).includes(currentValue),
    [options, currentValue],
  );

  const emptyOption = currentUnlisted && !currentValue && (
    <option value="" disabled aria-label="Not selected"></option>
  );

  return (
    <FormGroup>
      {label && <ControlLabel>{label}</ControlLabel>}
      {description && <label>{description}</label>}
      <select
        className="form-control"
        value={emptyOption ? '' : currentValue}
        onChange={handleChange}
        disabled={disabled}
      >
        {emptyOption}
        {(options || []).map(({ value, label }) => (
          <option value={value} key={value}>
            {label}
          </option>
        ))}
      </select>
      {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
    </FormGroup>
  );
}
