import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { useField } from 'formik';
import { castArray } from 'lodash';

export default function Input({
  label = '',
  description = '',
  collapse,
  normalize = collapse,
  sanitize = collapse,
  ...props
}) {
  const [normalizeScheduled, setNormalizeScheduled] = useState(true);

  const [{ onBlur: onBlurFormik, onChange: onChangeFormik, value, ...field }, meta, { setValue }] =
    useField(props);

  const onBlurWrap = useCallback(
    (...args) => {
      setNormalizeScheduled(true);
      return onBlurFormik?.(...args);
    },
    [onBlurFormik],
  );

  const onChangeWrap = useMemo(
    () =>
      sanitize
        ? ({ target }) => {
            const { value: valueRaw, selectionDirection, selectionStart, selectionEnd } = target;

            const [
              sanitizedValue,
              {
                selectionDirection: selectionDirectionNew = selectionDirection,
                selectionStart: selectionStartNew = selectionStart,
                selectionEnd: selectionEndSuggest = selectionEnd,
              } = {},
            ] = castArray(
              sanitize(valueRaw, {
                selectionStart,
                selectionEnd,
                selectionDirection,
              }),
            );

            const selectionEndNew =
              selectionDirectionNew === 'backward'
                ? Math.min(selectionStartNew, selectionEndSuggest)
                : Math.max(selectionStartNew, selectionEndSuggest);

            target.value = sanitizedValue;

            try {
              target.setSelectionRange(selectionStartNew, selectionEndNew, selectionDirectionNew);
            } catch (e) {
              // Might be exception on wrong numbers from sanitize, but better selection wierdness than input not working.
              console.error(e);
            }

            return setValue(sanitizedValue);
          }
        : onChangeFormik,
    [sanitize, onChangeFormik],
  );

  useEffect(() => {
    if (normalizeScheduled && normalize) {
      setNormalizeScheduled(false);
      const [normalizedValue] = castArray(normalize(value));
      if (normalizedValue !== value && normalizedValue !== undefined)
        setValue(normalizedValue, true);
    }
  }, [normalizeScheduled, value, normalize, setValue]);

  return (
    <FormGroup>
      {label && <ControlLabel>{label}</ControlLabel>}
      {description && <label>{description}</label>}
      <FormControl
        {...props}
        {...field}
        value={value}
        onBlur={onBlurWrap}
        onChange={onChangeWrap}
        isInvalid={!!meta.error && meta.touched}
      />
      {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
    </FormGroup>
  );
}
