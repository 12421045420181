import { sanitizeStrFromCenter, normalizeNumberFormat } from './common';

export const normalizeNumberInt = normalizeNumberFormat('0');

export const collapseNumberInt = (str, sanitizeOptions) => {
  if (!str) return str;

  if (!sanitizeOptions) return normalizeNumberInt(str);

  const sanitize = sanitizeStrFromCenter(strPart => strPart.replace(/[^\d, ]/g, ''));
  return sanitize(str, sanitizeOptions);
};
