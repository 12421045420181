import { isObject, mapValues } from 'lodash';

/**
 * There is an issue with Formik, which makes the validation results out of sync with current values, when setFieldValue is used.
 * Official Formik issue page: https://github.com/jaredpalmer/formik/issues/3345
 * Issue seem to be recurrent, this solution is based on this suggestion: https://github.com/jaredpalmer/formik/issues/2059#issuecomment-948412629
 */
export const formikSetFieldsHelper =
  ({ setFieldValue, setFieldTouched }) =>
  async fieldsValuesPairs => {
    await Promise.all(fieldsValuesPairs.map(([field, value]) => setFieldValue(field, value)));

    await Promise.all(fieldsValuesPairs.map(([field]) => setFieldTouched(field, true)));
  };

const errorsToTouchMap = errors =>
  mapValues(errors, error => (isObject(error) ? errorsToTouchMap(error) : true));

export const formikSetErrorFieldsTouchedHelper =
  ({ setTouched }) =>
  async errors => {
    return setTimeout(() => setTouched(errorsToTouchMap(errors), true), 1);
  };
