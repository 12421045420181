import numeral from 'numeral';

export const sanitizeStrFromCenter = sanitizeTransform => (str, sanitizeOptions) => {
  if (!str) return str;

  const { selectionStart = str?.length || 0 } = sanitizeOptions || {};

  const strBeforeSelection = str.substring(0, selectionStart);
  const strAfterSelection = str.substring(selectionStart);

  const normBeforeSelection = sanitizeTransform(strBeforeSelection);
  const normAfterSelection = sanitizeTransform(strAfterSelection);

  return [
    `${normBeforeSelection}${normAfterSelection}`,
    {
      selectionStart: normBeforeSelection.length,
      selectionEnd: normBeforeSelection.length,
    },
  ];
};

export const normalizeNumberFormat = format => str => {
  if (str === undefined || str.trim() === '') return '';

  const num = numeral(str);
  const val = num.value();
  if (typeof val !== 'number' || isNaN(val)) return undefined;

  return num.format(format);
};
