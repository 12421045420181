import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import _ from 'lodash';
import { Button, Row, Col, Glyphicon, Alert } from 'react-bootstrap';
import {
  storeVehicleFormValuesSaved,
  selectedGapQuote,
  selectedTwpQuote,
  selectedVscQuote,
  setSignatureFlow,
} from '../../../redux/modules/newQuote';
import { createSavedQuote } from '../../../redux/modules/savedQuote';
import PlanTable from './PlanTable';
import {
  CONTRACT_TYPE_GAP,
  CONTRACT_TYPE_TWP,
  CONTRACT_TYPE_VSC,
} from '../../../constants/contractType';

const FOOTER_BUTTON_STYLE = { marginRight: '5px' };

const scrollToTop = () => {
  const scrollable = document.querySelector('#root>.app-container-wrapper');
  scrollable.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'instant' });
};

export function FinalQuote(props) {
  const {
    vin,
    createSavedQuote,
    vehicleFormValues,
    vehicleFormValuesSaved,
    storeVehicleFormValuesSaved,
    vscQuote,
    gapQuote,
    twpQuote,
    extraCoverages,
    dealer,
    hasInvalidPagesBefore,
    dealerSettings,
  } = props;

  const printInfoRef = useRef();

  const handlePrint = () => {
    const content = printInfoRef.current?.innerHTML;
    if (!content) return undefined;

    const win = window.open('', 'Quote', 'height=800,width=600');

    win.document.write(`<html><head><title>Quote for ${vin}</title>`);
    win.document.write('</head><body >');
    win.document.write(content);
    win.document.write('</body></html>');
    win.document.close();

    win.focus();
    win.print();
  };

  const handleSaveQuote = useCallback(async () => {
    await createSavedQuote({
      ...vehicleFormValues,
      quoteType: vehicleFormValues?.quoteType?.join(','),
    });
    await storeVehicleFormValuesSaved();
  }, [vehicleFormValues, createSavedQuote, storeVehicleFormValuesSaved]);

  useEffect(() => {
    if (vehicleFormValuesSaved === false) handleSaveQuote();
  }, [vehicleFormValuesSaved, handleSaveQuote]);

  const handleProceedPrinted = () => {
    const { router, setSignatureFlow } = props;
    setSignatureFlow('printed');
    router.push(`/quote/rate`);
    scrollToTop();
  };

  const handleProceedESign = () => {
    const { router, setSignatureFlow } = props;
    setSignatureFlow('eSign');
    router.push(`/quote/rate`);
    scrollToTop();
  };

  const navigateToDashboard = () => {
    const { router } = props;
    router.push('/');
  };

  const coverages = [
    ..._.get(vscQuote, 'coverages', []).filter(c => !c.selectable),
    ...extraCoverages,
  ];
  const { eSignRequired } = dealer || {};

  return (
    <div>
      <div style={{ display: 'none' }}>
        <div ref={printInfoRef}>
          <h5>This vehicle qualifies as of {new Date().toLocaleString()} </h5>
          {vscQuote && (
            <PlanTable
              contractType={CONTRACT_TYPE_VSC}
              quote={vscQuote}
              request={vehicleFormValues}
              otherCoverages={coverages}
              dealerSettings={dealerSettings}
            />
          )}
          {gapQuote && (
            <PlanTable
              contractType={CONTRACT_TYPE_GAP}
              quote={gapQuote}
              request={vehicleFormValues}
              dealerSettings={dealerSettings}
            />
          )}
          {twpQuote && (
            <PlanTable
              contractType={CONTRACT_TYPE_TWP}
              quote={twpQuote}
              request={vehicleFormValues}
              dealerSettings={dealerSettings}
            />
          )}
        </div>
      </div>
      <div data-test-id="FinalQuote">
        <Row style={{ marginTop: 16 }}>
          <Col md={6} xs={12}>
            <Button
              bsStyle="default"
              className="ellipsis"
              onClick={handlePrint}
              style={FOOTER_BUTTON_STYLE}
            >
              Print Quote Info
            </Button>
            <div style={{ display: 'inline-flex', alignItems: 'center' }}>
              <Button
                bsStyle={vehicleFormValuesSaved ? 'default' : 'info'}
                className="ellipsis"
                onClick={handleSaveQuote}
                style={FOOTER_BUTTON_STYLE}
                disabled={hasInvalidPagesBefore || vehicleFormValuesSaved}
                data-test-id="FinalQuote-save"
              >
                Save Quote
              </Button>
              {vehicleFormValuesSaved && (
                <Alert color="info">
                  Quote was saved{' '}
                  <Button color="text" onClick={navigateToDashboard}>
                    Go to dashboard
                  </Button>
                </Alert>
              )}
            </div>
          </Col>
          <Col md={6} xs={12}>
            <Button
              bsStyle="link"
              className="ellipsis"
              onClick={() => alert('survey')}
              block
              style={{ fontSize: '18px' }}
            >
              Customer Satisfaction Survey
            </Button>
          </Col>
        </Row>
        <Row>
          <Col sm={12} style={{ display: 'flex', justifyContent: 'center', gap: 20 }}>
            <Button
              bsStyle="primary"
              bsSize="large"
              onClick={handleProceedESign}
              style={FOOTER_BUTTON_STYLE}
              disabled={hasInvalidPagesBefore}
              data-test-id="FinalQuote-proceed-eSign"
            >
              <Glyphicon glyph="phone" /> eSign Contract
            </Button>
            &nbsp;
            {!eSignRequired && (
              <Button
                bsStyle="primary"
                bsSize="large"
                onClick={handleProceedPrinted}
                style={FOOTER_BUTTON_STYLE}
                disabled={hasInvalidPagesBefore || eSignRequired}
                data-test-id="FinalQuote-proceed-non-eSign"
              >
                <Glyphicon glyph="print" /> Printed Contract
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
}

FinalQuote.propTypes = {
  vin: PropTypes.string.isRequired,
  router: PropTypes.object.isRequired,
  createSavedQuote: PropTypes.func.isRequired,
  vehicleFormValues: PropTypes.object.isRequired,
  vehicleFormValuesSaved: PropTypes.bool.isRequired,
  dealer: PropTypes.object.isRequired,
  vscQuote: PropTypes.object,
  gapQuote: PropTypes.object,
  twpQuote: PropTypes.object,
  extraCoverages: PropTypes.array,
  dealerSettings: PropTypes.object,
};

FinalQuote.defaultProps = {
  vscQuote: null,
  gapQuote: null,
  twpQuote: null,
  extraCoverages: null,
  dealerSettings: null,
};

export default compose(
  connect(
    (state, props) => {
      const newQuote = _.get(state, 'newQuote');
      const { vehicleFormValues, vehicleFormValuesSaved } = newQuote;
      const { router, location } = props;
      const vscQuote = selectedVscQuote({ newQuote });
      const gapQuote = selectedGapQuote({ newQuote });
      const twpQuote = selectedTwpQuote({ newQuote });
      return {
        vin: _.get(vehicleFormValues, 'vin'),
        dealer: _.get(state, 'dealer.details'),
        commercialType: _.get(newQuote, 'plans.ratingQuoteRequest.commercialType'),
        extraCoverages: _.values(newQuote.extraCoverages),
        vscQuote,
        gapQuote,
        twpQuote,
        dealerSettings: _.get(state, 'dealerSettings.data'),
        vehicleFormValues,
        vehicleFormValuesSaved,
        router,
        location,
      };
    },
    {
      createSavedQuote,
      storeVehicleFormValuesSaved,
      setSignatureFlow,
    },
  ),
)(FinalQuote);
